<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center">Central Library Report</v-card-title>
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <v-col>
              <label class="add-text">Series</label>
              <v-autocomplete
                v-model="series"
                placeholder="Select Series"
                :items="series_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>
            <v-col>
              <label>Department</label>
                    <v-autocomplete
                v-model="dept"
                placeholder="Select Department"
                :items="dept_list"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
              </v-col>              
                
            <v-col>
            
               
               <label>Book Type</label>
                <v-select 
                v-model="mtype"
                placeholder="Select Book Type"
                multiple
                :items="materialtypelist"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
                
              ></v-select>
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1"
                >Submit</v-btn
              >
            </center>
          </div>
        </div>
     
      </div>
    </v-card>
    <div>
    <v-card v-if="loading || Array.isArray(tabledata) && tabledata.length" :headers="tableheader" class="pt-5 mt-5">
      <v-skeleton-loader
      v-if="loading "
          v-bind="attrs"
          type="three-line,  article"
        ></v-skeleton-loader>
    <v-data-table v-else-if="Array.isArray(tabledata) && tabledata.length" disable-pagination=true group-by="series" :headers="tableheader" :items="tabledata"  :search="search">
     <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title >CENTRAL LIBRARY REPORT</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            
                <excelheader  v-if="tabledata.length != 0" :headers="tableheader" :tabledata="tabledata" report_name="CENTRAL LIBRARY REPORT">
    </excelheader>
            </v-toolbar>
          </template>
    </v-data-table>
</v-card >

 
    </div>    <!-- <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css";
import { table2excel } from "../jquery.table2excel";
import xlsx_header from "@/components/common_table_header.vue"; // css needs to be imported for each dashboard
export default {
  components: {
    "excelheader": xlsx_header
  },
  data: () => ({
  
loading:false,
    dept: null,
    usertype: null,
   search:"",
    overlay: false,
    
    bookitemid: "",
   
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    mtype:[],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    org: "",
    materialtypelist:[],
    materialtype:"",
    dialog: false,
  
    series_data: [],
    
    series: "",
   tableheader:[],

dept_list:[],
  }),

  mounted() {
    this.onLoad();
  },
  methods: {
   
    dailyData1() {
      // alert("in get status")
              
       this.loading = true;

      const data = {
        series_id: this.series,
        dept_id:this.dept,
        materialtype_id:this.mtype,
       // flag:this.flag
      };
      axios
        .post("/Librarian/get_centrallibrary_data", data)
        .then((res) => {
          console.log(res.data);
          if (res.data.msg == "200") {
             this.loading = false;
            this.tabledata = res.data.data;
            this.tableheader=res.data.tableheader;
            console.log(this.tabledata)
            if(this.tabledata == null){
                this.showSnackbar("#b71c1c", "No Books Found with Selected Book Type");
                 this.loading = false;
            }
          }
          else{
               this.showSnackbar("#b71c1c", res.data.msg);
                this.loading = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
           this.loading = false;
        })
        .finally(() => {
           this.loading = false;
        });
       
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getcentrallibraryData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            this.materialtypelist = res.data.materialarray;
            this.materialtypelist.push({ name: "ALL", id: "ALL" });
            this.mtype.push("ALL");             
            this.dept_list= res.data.dept_list;
            this.dept_list.push({ name: "ALL", id: "ALL" });
            this.dept = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
.add-section {
  display: block;
  margin: 1rem;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}
.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}
.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}
.checkbox .box {
  margin-top: 5px;
}
.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
